import React, { useState, useEffect } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default () => {
  const [inputAge, setInputAge] = useState(3)
  const [monthlyRevenue, setmonthlyRevenue] = useState(11000)
  const [monthlyProfit, setmonthlyProfit] = useState(3000)
  const [inputTime, setinputTime] = useState(10)
  const [valuation, setValuation] = useState(0)

  useEffect(() => {
    const newValue = calcValuation()
    if (!isNaN(newValue)) setValuation(Math.trunc(newValue).toLocaleString())
  }, [inputAge, monthlyRevenue, monthlyProfit, inputTime])

  const calcValuation = () => {
    const margin = monthlyProfit / monthlyRevenue
    const multiplierMin = 20
    const multiplierMax = 40
    //normalize inputs
    // N = (input -min)/(max-min)
    const inputAge_N = (inputAge - 1) / (10 - 1)
    const margin_N = (margin - 0) / (80 - 0)
    const inputTime_N = Math.abs(inputTime - 60) / (60 - 0)

    //weights
    const inputAge_W = 0.4
    const margin_W = 0.3
    const inputTime_W = 0.3

    const weightedSum =
      inputAge_W * inputAge_N + margin_W * margin_N + inputTime_W * inputTime_N

    //denormalize
    const multiplier =
      weightedSum * (multiplierMax - multiplierMin) + multiplierMin
    return multiplier * monthlyProfit
  }

  return (
    <Layout>
      <SEO
        title="Valuation Tool"
        description="Learn how much you could sell your business for."
      />
      <h1 style={{ textAlign: "center" }}>Valuation Tool</h1>
      <div className="valuation-tool">
        <div className="tool-inputs">
          <div className="row">
            <div className="column50">
              <h3>How Many Years Has the Business Operated?</h3>
            </div>
            <div className="column50 input-side">
              {/* <span>&lt;1</span> */}
              <div className="slidecontainer">
                <input
                  type="range"
                  min="1"
                  max="10"
                  value={inputAge}
                  className="tool-slider"
                  onChange={e => setInputAge(e.target.value)}
                />
                <output
                  className="bubble"
                  style={{
                    left: `calc(${Number(
                      ((inputAge - 1) * 100) / (10 - 1)
                    )}% + (${8 -
                      Number(((inputAge - 1) * 100) / (10 - 1)) * 0.15}px))`,
                  }}
                >
                  {inputAge <= 1 && "<"}
                  {inputAge}
                  {inputAge >= 10 && "+"}
                </output>
              </div>
              {/* <span>10+</span> */}
              {/* <p>
                <span id="demo">{inputAge}</span>
              </p> */}
            </div>
          </div>
          <div className="row">
            <div className="column50">
              <h3>What is the Monthly Revenue?</h3>
            </div>
            <div className="column50 input-side">
              <span>$&nbsp;</span>
              <input
                min="1"
                placeholder="10,500"
                type="number"
                value={monthlyRevenue}
                className="tool-input"
                onChange={e => setmonthlyRevenue(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="column50">
              <h3>What is the Monthly Profit?</h3>
            </div>
            <div className="column50 input-side">
              <span>$&nbsp;</span>
              <input
                placeholder="3,200"
                min="1"
                type="number"
                value={monthlyProfit}
                className="tool-input"
                onChange={e => setmonthlyProfit(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="column50">
              <h3>How Much Time Does the Owner Spend Working Per Week?</h3>
            </div>
            <div className="column50 input-side">
              {/* <span>&lt;1</span> */}
              <div className="slidecontainer">
                <input
                  type="range"
                  min="0"
                  max="60"
                  value={inputTime}
                  className="tool-slider"
                  onChange={e => setinputTime(e.target.value)}
                />
                <output
                  className="bubble"
                  style={{
                    left: `calc(${Number(
                      ((inputTime - 0) * 100) / (60 - 0)
                    )}% + (${8 -
                      Number(((inputTime - 0) * 100) / (60 - 0)) * 0.15}px))`,
                  }}
                >
                  {inputTime}
                  {inputTime >= 60 && "+"}
                  {inputTime > 0 ? " hrs" : " hr"}
                </output>
              </div>
            </div>
          </div>
        </div>
        <div className="tool-results">
          <h2>Your Business is Estimated to Be Worth:</h2>
          <div className="final-calc">${valuation}</div>
        </div>
      </div>
    </Layout>
  )
}
